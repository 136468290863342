.upload-button-container {
  position: relative;
  height: 100px;
  width: 100px;
  border: 1px solid var(--primary-color);
  font-size: 48px;
  border-radius: 15px;
  color: var(--primary-color);
  cursor: pointer;
}

.upload-button-container:hover {
  background-color: var(--primary-color);
  color: #fff;
}

.upload-button-container.not-empty {
  display: none;
}

.upload-button-container:hover {
  background-color: var(--primary-color);
  color: #fff;
}

.upload-button {
  width: 100px;
  height: 100px;
  opacity: 0;
  position: absolute;
  cursor: pointer !important;
}

.uploaded-image {
  position: absolute;
  object-fit: fill;
}

.uploaded-image-container {
  border: 1px solid var(--primary-color);
  border-radius: 8px;
  position: relative;
  width: 300px;
  aspect-ratio: 3/4;
  object-fit: fill;
  object-position: center;
}

.uploaded-image-container.no-border {
  border: none;
}

.black-overlay {
  position: absolute;
  top: 0;
  height: 40%;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.gap-columns {
  gap: 10px 25px;
}
.remove-image {
  top: -15px;
  right: -15px;
  background-color: var(--primary-color);
  vertical-align: middle;
  font-size: 18px;
}

.form {
  color: #626262;
}

.form-error {
  color: var(--error);
  min-height: 16px;
  line-height: 14px;
  padding: 0;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  border-color: var(--primary-color) !important;
  box-shadow: 0 0 0 1px var(--primary-color-variant) !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: var(--primary-color) !important;
}

.p-password-input {
  width: 100% !important;
}

.p-inputtext:hover,
.p-inputtext:focus {
  border-color: var(--primary-color) !important;
}

.p-inputtext:focus {
  box-shadow: 0 0 0 2px var(--primary-color) !important;
}

.tri-state-switch {
  -webkit-appearance: none;
  appearance: none;
  height: 32px;
  width: 70px !important;
  border-radius: 20px;
  outline: transparent;
  overflow: hidden;
  padding: 0 3px;
}

.tri-state-switch.on {
  background: var(--primary-color);
}

.tri-state-switch.off {
  background: var(--error);
}

.tri-state-switch.neutral {
  background: gray;
}

.tri-state-switch::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 26px;
  background: #eaeaea;
  width: 26px;
  border-radius: 20px;
}

.p-dropdown:hover,
.p-focus {
  border-color: var(--primary-color) !important;
}

.p-component,
.p-radiobutton-box {
  outline: transparent !important;
  box-shadow: none !important;
}

.p-radiobutton-box:hover {
  border-color: var(--primary-color) !important;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: var(--primary-color) !important;
  background: var(--primary-color) !important;
}

.p-button-icon-only {
  background-color: var(--primary-color) !important;
  box-shadow: none !important;
  border-color: transparent !important;
  outline: transparent !important;
}

.p-button-icon-only:hover {
  background-color: var(--primary-color-variant) !important;
}

.p-invalid {
  border-color: var(--error) !important;
}
