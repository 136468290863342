i {
  font-size: inherit;
  color: inherit;
}

/* #region --------------------------------  primary button styling -------------------------------- */
.custom-button:not(.delete-button),
.custom-button .p-button,
.p-button.p-confirm-dialog-accept {
  background-color: var(--primary-color) !important;
  gap: 8px;
  border: none !important;
}

.custom-button.icon-button {
  background-color: transparent !important;
  gap: 8px;
  color: #000 !important;
  border: none !important;
}
.custom-button:disabled,
.custom-button .p-splitbutton.p-disabled,
.p-button.p-confirm-dialog-accept:disabled {
  background-color: grey !important;
}

.custom-button.icon-button:hover {
  background-color: transparent !important;
}

.custom-button:hover,
.custom-button .p-button:hover,
.p-button.p-confirm-dialog-accept:hover {
  background-color: var(--primary-color-variant) !important;
}

.custom-button:focus,
.custom-button.icon-button:focus,
.custom-button .p-button:focus,
.p-button.p-confirm-dialog-accept {
  box-shadow: none !important;
}
/* #endregion -------------------------------- primary button styling -------------------------------- */

.p-progress-spinner-circle {
  stroke: var(--primary-color) !important;
  stroke-width: 4px;
}

.disabled-button .p-progress-spinner-circle {
  stroke: #fff !important;
}

.p-datatable-wrapper {
  border-radius: 10px;
  width: 100% !important;
}

.p-inputswitch .p-inputswitch-slider {
  background-color: var(--error) !important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background-color: var(--primary-color) !important;
}

.article-image > .uploaded-image {
  object-fit: cover !important;
}

.test p {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0;
  margin: 0;
}

a.link {
  color: #000000 !important;
  text-decoration: none !important;
}

.p-inplace-content {
  display: flex !important;
  gap: 10px;
  flex-wrap: wrap;
  /* flex-direction: column; */
  padding-top: 10px;
}
.p-inplace-content-close {
  margin: 0 !important;
  background-color: var(--error) !important;
  border: none !important;
}

.transparent.custom-button.p-button {
  background: transparent !important;
}

.p-button.p-highlight {
  background: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  outline: transparent !important;
  box-shadow: none !important;
}

.p-carousel-container {
  height: 550px;
}

.p-carousel-indicators {
  display: none !important;
}

.p-splitbutton-menubutton {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.p-row-toggler:focus {
  outline: transparent !important;
  box-shadow: none !important;
}

/* #region -------------------------------- Banners -------------------------------- */
.default-image-banner {
  overflow: hidden;
  width: 100%;
  max-width: 980px;
}
.default-image-banner img {
  width: 100%;
  max-height: 8rem;
  object-fit: cover;
}

.login-banner {
  position: relative;
  width: 64%;
  object-fit: cover;
}

.login-banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.a3-image-banner {
  max-width: 90%;
}

.C2-margin {
  margin-block: 20px;
}

.C3-margin {
  margin-block: 20px;
}
/* #endregion -------------------------------- Banners -------------------------------- */

.p-sidebar.p-sidebar-open {
  box-shadow: 10px 0 0px var(--primary-color) !important;
}

.p-treenode-content:focus {
  box-shadow: none !important;
  background: var(--primary-color);
}

.p-treenode-content.p-highlight {
  background: var(--primary-color) !important;
  color: #ffffff !important;
}

.p-treenode-content.p-treenode-selectable:not(.p-highlight):hover,
.p-tree .p-tree-container .p-treenode .p-treenode-content a:hover {
  background: transparent !important;
  color: var(--primary-color) !important;
}
.p-tree-toggler:focus {
  outline: none !important;
  box-shadow: none !important;
}

.p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content.p-highlight
  .p-tree-toggler,
.p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content.p-highlight
  .p-treenode-icon {
  color: #ffffff !important;
}

.p-treenode-icon {
  font-size: 24px !important;
  color: #ffffff !important;
  margin-right: 15px !important;
}

.p-datatable-loading-icon {
  color: #ffffff;
  font-size: 70px !important;
}

.refresh-button:hover {
  background: transparent !important;
}

.refresh-button {
  color: var(--primary-color) !important;
  background: transparent !important;
}

.ant-upload-list-item-container {
  width: 170px !important;
}

.ql-editor {
  display: flex;
  flex-direction: column;
  border: 1px solid #d9d9d9;
  height: 120px !important;
  width: 100%;
  overflow: auto;
  padding: 10px;
}

.ql-editor:hover {
  border: 1px solid var(--primary-color);
}

.ant-table-expanded-row-fixed-custom {
  margin-left: -16px;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
  width: 100%;
}

.editable-cell-value-wrap:hover {
  padding: 4px 11px;
  min-height: 32px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.select-popup-hide-selected-options .ant-select-item-option-selected {
  display: none;
}

.select-popup-hide {
  display: none;
}

.deals-country {
  top: 10px;
  left: 10px;
  font-size: 13px;
  line-height: 18px;
  font-weight: 700;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: absolute;
  padding: 2px 8px;
  background: rgba(255, 255, 255, 0.7);
  color: rgb(0, 0, 0);
  z-index: 100;
  border-radius: 3px;
  background: rgb(0, 214, 188);
  font-size: 11px;
  opacity: 0.9;
}
