body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Montserrat', Helvetica, Arial, serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8f8 !important;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #cdcdcd70;
  -webkit-box-shadow: 0 0 1px #ffffff80;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: unset;
}

:root {
  --primary-color: #00d6bc !important;
  --primary-color-variant: #00d6bc80 !important;
  --background-color: #1c1c1c !important;
  --error: #df7975;
}

.primary-border {
  border: 1px solid var(--primary-color);
}

.scrollable-content {
  overflow: auto;
  height: 300px; /* Adjust the height as needed */
  width: 300px; /* Adjust the width as needed */
}

.show-scrollbar {
  overflow: auto !important;
}

.p-editor-container .p-editor-content .ql-editor {
  min-height: 200px !important;
}
